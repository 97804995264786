import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import VersionList from '../../components/VersionsList'

const thirdPartySoftware = () => {
    const data = useStaticQuery(graphql`
        query ThirdPartySofwareSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Legals_Third_Party_Software" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />

            <section className="my-5 py-5 bg-gray">
                <h1 className="text-center my-4">Third Party Software</h1>
            </section>

            <section className="my-5 py-5">
                <div className="container-1">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <b>Please choose the version of lenses: </b>

                            <VersionList />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default thirdPartySoftware
